import styled from "styled-components";

import { InputWrapper, LabelText } from "src/comps/Atom/Inputs/Input/styles";

interface SuggesterFullWrapperProps {
  hasFocus: boolean;
}

export const SuggesterFullWrapper = styled.div<SuggesterFullWrapperProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 5px 8px;
  background: ${props => props.theme.colors.grey8};
  border-radius: 8px;
  border: 2px solid ${props => props.theme.colors.grey5};

  ${InputWrapper} {
    width: calc(100% - 26px);
    padding: 0;
    border: none;
    background: none;
    right: 8px;

    ${LabelText} {
      display: none;
    }

    &:hover {
      border: none;
    }

    input {
      padding: 0;
      padding-left: 5px;
    }

    @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    input:focus {
      animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
    }

    svg {
      margin: 0 0 0 8px;
    }
  }

  ${({ theme, hasFocus }) => hasFocus && `
    border: none;
    border-bottom: 2px solid ${theme.colors.grey5};
    border-radius: 0;
  `}  
`;
