import styled from 'styled-components';
import { ButtonBase } from 'src/comps/Atom/Buttons/Button/styles';
import { PillWrapper } from 'src/comps/Atom/Pill/styles';

import { updatedMedia } from 'client/styles';

export const OmniSearchOverlay = styled.div`
  position: fixed;
  top: 76px;
  left: 0;
  height: calc(100vh - 78px);
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.grey1};
  opacity: 0.4;
  z-index: 1;
`;

export const OmniSearchContent = styled.div`
  &.borderless {
    display: flex;
    align-items: center;
    ${PillWrapper} {
      border: 2px solid ${({ theme }) => theme.colors.grey8};
      overflow: hidden;
      padding: 8px;
      gap: 6px;
      span {
        text-align: left;
        width: 100%;
        max-width: 200px;
        display: table;
        table-layout: fixed;
      }
      &:hover {
        border: 2px solid ${({ theme }) => theme.colors.grey2};
      }
    }
  }
  &.readonly {
    display: flex;
  }
`;

export const OmniSearchWrapper = styled.div``;

export const OmniSearchBase = styled.div<{ isEbooking?: boolean }>`
  position: relative;

  &.readonly,
  &.borderless {
    ${props => (props.isEbooking ? `background-color: ${props.theme.colors.grey8};` : '')}
    display: flex;
    align-items: center;
    border: 2px solid ${({ theme }) => theme.colors.grey5};
    border-radius: 50px;
  }

  &.readonly {
    flex-wrap: wrap;
    padding: 5px 10px 6px 36px;
    margin: 0 12px;
    max-width: calc(100% - 78px);
    overflow: hidden;
    svg {
      position: absolute;
      fill: ${({ theme }) => theme.colors.grey3};
      left: 10px;
      top: calc(50% - 10px);
    }
    ${OmniSearchWrapper} {
      width: 100%;
    }
  }

  &.borderless {
    padding: 3px;
    margin: 0 12px;
    ${ButtonBase} {
      line-height: 16px;
      padding: 10px 12px;
      border-radius: 50px;
    }
    ${OmniSearchWrapper} {
      display: flex;
    }
  }

  &.full {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100vw;
    background-color: ${({ theme }) => theme.colors.grey8};
    padding: 20px;
    z-index: 11;
    ${updatedMedia.onlyTablet} {
      top: 76px;
    }
  }
`;
