import React, { useState } from "react";
import { useIntl } from "react-intl";

import Room from "../Room";
import { MAX_ROOMS } from "../constants";
import { LinkButton, PaxSelectorContent, PaxSelectorWrapper, PaxesFooter, PaxesMobileHeader } from "./styles";
import { Room as RoomType, SearchMode } from "src/types/search";
import PrimaryButton from "src/comps/Atom/Buttons/PrimaryButton";
import Close from "client/icons/Close";

interface IPaxesSelectorProps {
  paxes: RoomType[];
  onChange: (value: RoomType[]) => void;
  onClose: () => void;
  mode: SearchMode;
}

function PaxesSelector({ paxes, onChange, onClose, mode }: IPaxesSelectorProps) {
  const intl = useIntl();

  const [rooms, setRooms] = useState(() => {
    return paxes.map((room, index) => {
      return {
        roomNumber: index + 1,
        ...room
      }
    })
  });
  const [isValidationOn, setIsValidationOn] = useState(false);

  const reportChange = (newRooms: Array<{ roomNumber: number } & RoomType>) => {
    onChange(newRooms.map(({ adults, children }) => ({ adults, children })));
    setIsValidationOn(false)
  }

  const handleChangeRoom = (roomNumber: number, values: RoomType) => {
    const newRooms = rooms.map((room) => {
      if (room.roomNumber === roomNumber) {
        return {
          roomNumber,
          ...values
        }
      }
      return room;
    });
    setRooms(newRooms);
    setIsValidationOn(false);
  }

  const handleRemoveRoom = (roomIndex: number) => {
    const newRooms = rooms
      .filter((_, index) => index !== roomIndex)
      .map((room, index) => ({ ...room, roomNumber: index + 1 }));

    setRooms(newRooms);
    setIsValidationOn(false);
  }

  const handleAddRoom = () => {
    const newRooms = [
      ...rooms,
      {
        roomNumber: rooms.length + 1,
        adults: 2,
        children: []
      }
    ];
    setIsValidationOn(false);
    setRooms(newRooms);
  }

  const handleSave = () => {
    setIsValidationOn(true);
    const isInvalid = rooms.some(room => room.children.some(child => child === null))
    if (isInvalid) return;

    reportChange(rooms);
    onClose();
  }

  return (
    <PaxSelectorWrapper data-testid='pax-selector' mode={mode}>
      <PaxSelectorContent mode={mode}>
        <PaxesMobileHeader mode={mode}>
          {intl.formatMessage({ id: 'global.guests' })}
          <Close onClick={onClose} size={24} />
        </PaxesMobileHeader>

        {rooms.map((room, index) => {
          const position = index;
          const roomNumber = position + 1;
          return (
            <Room
              {...room}
              key={position}
              isValidationOn={isValidationOn}
              onChange={(values) => handleChangeRoom(roomNumber, values)}
              onRemove={() => handleRemoveRoom(position)}
            />
          )
        })}
      </PaxSelectorContent>
      <PaxesFooter>
        {rooms.length < MAX_ROOMS ? (
          <LinkButton onClick={handleAddRoom}>
            {intl.formatMessage({ id: 'pax.add-room' })}
          </LinkButton>
        ) : <span />}
        <PrimaryButton size="m" type="button" onClick={handleSave}>
          {intl.formatMessage({ id: 'global.save' })}
        </PrimaryButton>
      </PaxesFooter>
    </PaxSelectorWrapper>
  );
}

export default PaxesSelector;
