import React from "react"
import { Error } from "./styles";

type InputErrorProps = {
  errors: string[]
}

function InputError({ errors }: InputErrorProps) {
  return (
    <>
      {errors.map((error) => (
        <Error key={error} data-testid='input-error'>
          {error}
        </Error>
      ))}
    </>
  );
}

export default InputError
