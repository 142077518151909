import styled from 'styled-components';

import { updatedMedia } from 'client/styles';
import { ButtonIcon } from 'src/comps/Button/styles';
import { 
  CalendarBase as RangeCalendarBase, 
  WeekDays, 
  Month,
  StartDate,
  EndDate,
  SelectedDate,
  DefaultDay
} from 'client/components/DatePicker/styles';

export const CalendarBase = styled.div`
  height: calc(100% - 154px);
  z-index: 1;
  ${updatedMedia.desktop} {
    padding: 30px;
    padding-bottom: 20px;
    position: absolute;
    top: calc(100% + 10px);
    left: -210px;
    height: auto;
    border: 2px solid ${({ theme }) => theme.colors.grey2};
    border-radius: 8px 8px;
    background-color: ${({ theme }) => theme.colors.grey8};
    z-index: 11;
  }
  ${WeekDays} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    ${updatedMedia.desktop} {
      margin-bottom: 8px;
    }
  }
  ${RangeCalendarBase} {
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
    ${updatedMedia.desktop} {
      border: none;
      padding: 0;
    }
  }
  ${Month} {
    padding: 0 8px 8px 8px;
  }
  ${StartDate}, ${EndDate} {
    color: ${({ theme }) => theme.colors.grey8};
    background-color: ${({ theme }) => theme.colors.grey6};
    span {
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.grey2};
      border: none;
    }
  }
  ${SelectedDate} {
    background-color: ${({ theme }) => theme.colors.grey6};
  }
  ${DefaultDay} {
    &:hover {
      span {
        border-radius: 100%;
        border: 2px solid ${({ theme }) => theme.colors.grey2};
      }
    }
  }
`;

export const AddMonths = styled(ButtonIcon)`
  width: 100%;
  padding: 15px 0;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  ${updatedMedia.desktop} {
    display: none;
  }
`;

export const CalendarWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey5};
  padding-bottom: 25px;
  margin-bottom: 16px;
  overflow-y: auto;
  height: 100%;
  ${updatedMedia.desktop} {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  svg {
    display: none;
    ${updatedMedia.desktop} {
      display: block;
    }
  }
  ${AddMonths} {
    svg {
      display: inline-block;
    }
  }
  ${updatedMedia.desktop} {
    display: flex;
  }
`;
