import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useIntl, FormatDateOptions } from 'react-intl';
import { format } from 'date-fns';
import { useClickAway, useEffectOnce } from 'react-use';

import Calendar from 'src/comps/Molecules/Calendar';
import IconClose from 'client/icons/Close';
import {
  DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT,
  formatMappings,
} from 'src/comps/DateIntl/constants';
import PrimaryButton from 'src/comps/Atom/Buttons/PrimaryButton';
import { SearchMode } from 'src/types/search';
import SearchIcon from 'client/icons/Search';
import useBus from 'src/hooks/useBus';

import {
  CheckinDate,
  CheckoutDate,
  DatesContainer,
  DatePickerBase,
  ButtonClose,
  Ready,
  Spacer,
} from './styles';

interface IDateRange {
  checkIn: Date;
  checkOut: Date;
}

enum OperationMode {
  Resting = 'resting',
  Checkin = 'checkin',
  Selecting = 'selecting',
  Checkout = 'checkout',
}

interface IProps {
  initialValue: IDateRange;
  firstAvailableDate: Date;
  onChange: ({ checkIn, checkOut }: IDateRange) => void;
  mode: SearchMode;
}

const DatePicker = ({ initialValue, firstAvailableDate, onChange, mode }: IProps) => {
  const intl = useIntl();
  const containerElement = useRef(null);
  const [operationMode, setOperationMode] = useState<OperationMode>(OperationMode.Resting);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState(initialValue);
  const { subscribe } = useBus();

  useEffect(() => setSelectedDates(initialValue), [initialValue]);

  const dateOptions = useMemo(() => {
    if (mode === SearchMode.Full && openCalendar) {
      return formatMappings[DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT] as FormatDateOptions;
    }
    return {
      day: '2-digit',
      month: 'short',
    } as FormatDateOptions;
  }, [mode, openCalendar]);

  const handleCalendar = (type: OperationMode) => {
    if (mode === SearchMode.Readonly) return;
    setOperationMode(type);
    setOpenCalendar(true);
  };

  const handleChange = ({ checkIn, checkOut }: IDateRange) => {
    if (operationMode === OperationMode.Checkin) {
      setSelectedDates({ checkIn, checkOut });
      setOperationMode(OperationMode.Selecting);
      onChange({ checkIn, checkOut });
      return;
    }
    if (operationMode === OperationMode.Checkout || operationMode === OperationMode.Selecting) {
      const isSearchModeFull = mode === SearchMode.Full;
      setSelectedDates({ checkIn, checkOut });
      setOpenCalendar(isSearchModeFull);
      setOperationMode(isSearchModeFull ? OperationMode.Checkin : OperationMode.Resting);
      onChange({ checkIn, checkOut });
    }
  };

  function closeCalendar() {
    setOperationMode(OperationMode.Resting);
    setOpenCalendar(false);
  }

  useClickAway(containerElement, closeCalendar);

  useEffectOnce(() =>
    subscribe('open-datepicker', () => {
      if (mode !== SearchMode.Borderless) return;
      handleCalendar(OperationMode.Checkin);
    })
  );

  // endDate has to be null when has been selected only checkin to put unavailable dates
  const endDate = operationMode === OperationMode.Selecting ? null : selectedDates.checkOut;
  const minDate =
    operationMode !== OperationMode.Checkin ? selectedDates.checkIn : firstAvailableDate;
  const isFocusedCheckIn = operationMode === OperationMode.Checkin;
  const checkinFormat = isFocusedCheckIn ? selectedDates.checkIn : minDate;
  const buttonMessage = endDate
    ? `${format(checkinFormat, 'd MMM.')} - ${format(endDate, 'd MMM.')}`
    : 'Select a check-out date';

  return (
    <DatePickerBase
      className={`${mode} date-picker-wrapper ${operationMode}`}
      operationMode={operationMode}
      data-testid="date-picker"
      ref={containerElement}
      openCalendar={openCalendar}
    >
      <DatesContainer>
        {openCalendar && (
          <ButtonClose onClick={closeCalendar} data-testid="button-close">
            <IconClose />
          </ButtonClose>
        )}
        <Spacer />
        <CheckinDate
          onClick={() => handleCalendar(OperationMode.Checkin)}
          testId="checkin-date"
          className="checkin-date"
        >
          <div>{intl.formatMessage({ id: 'search.checkin' })}</div>
          <span>{intl.formatDate(selectedDates.checkIn, dateOptions)}</span>
        </CheckinDate>
        <Spacer />
        <CheckoutDate
          onClick={() => handleCalendar(OperationMode.Checkout)}
          testId="checkout-date"
          className="checkout-date"
        >
          <div>{intl.formatMessage({ id: 'search.checkout' })}</div>
          <span>{!endDate ? '-' : intl.formatDate(endDate as Date, dateOptions)}</span>
        </CheckoutDate>
        <Spacer />
      </DatesContainer>
      {openCalendar && (
        <>
          <Calendar
            startDate={selectedDates.checkIn}
            endDate={endDate}
            onChange={handleChange}
            minDate={minDate}
            isFocusedCheckIn={isFocusedCheckIn}
          />
          <Ready>
            <span>{buttonMessage}</span>
            <PrimaryButton
              size="m"
              type="button"
              onClick={closeCalendar}
              disabled={!endDate}
              leftIcon={<SearchIcon size={20} />}
              data-testid="ready-button"
            >
              {intl.formatMessage({ id: 'global.search' })}
            </PrimaryButton>
          </Ready>
        </>
      )}
    </DatePickerBase>
  );
};

export default DatePicker;
