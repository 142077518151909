import React from "react";
import { noop } from "lodash";

import { AgeRange, Inputs, ItemSelector, ItemSelectorTitle, RemoveRoom, RoomHeader, RoomTitle, RoomWrapper, TitleAges } from "./styles";
import IncrementSelector from "client/components/IncrementSelector";
import { useIntl } from "react-intl";
import AgeSelector from "src/comps/AgeSelector";
import { MAX_ADULTS, MAX_CHILDREN } from "../constants";
import { Room as RoomType } from "src/types/search";

interface IRoomProps {
  roomNumber: number;
  onChange?: (value: RoomType) => void;
  onRemove?: () => void;
  isValidationOn?: boolean;
};

function Room({ roomNumber, adults, children = [], onChange = noop, onRemove, isValidationOn = false }: IRoomProps & RoomType) {
  const intl = useIntl();

  const onChangeAdultsNumber = ({ adultsNumber }: { adultsNumber: number }) => {
    onChange({
      adults: adultsNumber,
      children
    })
  }

  const onChangeChildrenNumber = ({ childrenNumber }: { childrenNumber: number }) => {
    const newChildren = Array.from({ length: childrenNumber }).map((_, index) => children[index] ?? null);
    onChange({
      adults,
      children: newChildren
    })
  }

  const onChangeChildAge = (index: number, age: number) => {
    const newChildren = [...children];
    newChildren[index] = age;
    onChange({
      adults,
      children: newChildren
    })
  }


  const AdultsItemSelectorTitle = <ItemSelectorTitle>{intl.formatMessage({ id: 'global.adults' })}</ItemSelectorTitle>
  const ChildrenItemSelectorTitle = (
    <>
      <ItemSelectorTitle>{intl.formatMessage({ id: 'global.children' })}</ItemSelectorTitle>
      <AgeRange>
        {intl.formatMessage({ id: 'global.age' })} 0-17
      </AgeRange>
    </>
  );

  return (
    <RoomWrapper isFirstRoom={roomNumber === 1}>
      <RoomHeader>
        <RoomTitle>{intl.formatMessage({ id: 'global.room' })} {roomNumber}</RoomTitle>
        {roomNumber > 1 && (
          <RemoveRoom onClick={onRemove}>
            {intl.formatMessage({ id: 'pax.remove-room' })}
          </RemoveRoom>
        )}
      </RoomHeader>
      <ul>
        <ItemSelector>
          <IncrementSelector
            maxLimit={MAX_ADULTS}
            minLimit={1}
            name="adultsNumber"
            title={AdultsItemSelectorTitle}
            update={onChangeAdultsNumber}
            value={adults}
          />
        </ItemSelector>
        <ItemSelector>
          <IncrementSelector
            maxLimit={MAX_CHILDREN}
            minLimit={0}
            name="childrenNumber"
            title={ChildrenItemSelectorTitle}
            update={onChangeChildrenNumber}
            value={children?.length ?? 0}
          />
        </ItemSelector>
      </ul>
      {children.length > 0 && (
        <div>
          <TitleAges>
            {intl.formatMessage({ id: 'pax.children-ages-title' })}
          </TitleAges>
          <Inputs>
            {children.map((item, childIndex) => {
              const key = childIndex;
              return (
                <AgeSelector
                  key={`${roomNumber}-${key}`}
                  initialAge={children[childIndex]}
                  isValidationOn={isValidationOn}
                  item={children[childIndex]}
                  position={`${roomNumber}-${childIndex}`}
                  titleTranslation={{ id: 'pax.children-ages-title', defaultMessage: '' }}
                  update={age => onChangeChildAge(childIndex, age)}
                />
              );
            })}
          </Inputs>
        </div>
      )}
    </RoomWrapper>
  );
}

export default Room;
