import React from 'react';
import { useIntl } from 'react-intl';

import { SearchMode } from "src/types/search";

import { PaxesBase } from './styles';


interface IPaxesTextProps {
  paxes: {
    rooms: number;
    adults: number;
    children: number;
  };
  separator?: string;
  mode: SearchMode;
}

const PaxesText = ({ paxes, separator = ' · ', mode }: IPaxesTextProps) => {
  const { rooms, adults, children } = paxes;
  const intl = useIntl();

  const parts = [];
  if (rooms > 0) {
    parts.push(`${rooms} ${intl.formatMessage({ id: rooms === 1 ? 'global.room' : 'global.rooms' })}`);
  }
  if (adults > 0) {
    parts.push(`${adults} ${intl.formatMessage({ id: adults === 1 ? 'global.adult' : 'global.adults' })}`);
  }
  if (children > 0) {
    parts.push(`${children} ${intl.formatMessage({ id: children === 1 ? 'global.child' : 'global.children' })}`);
  }

  return (<PaxesBase className={mode}>{parts.join(separator)}</PaxesBase>);
};

export default PaxesText;
