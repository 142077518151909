import React, { ReactNode } from 'react';

import { DateWrapper } from './styles';

interface IProps {
  onClick: () => void;
  className?: string;
  children: ReactNode;
  testId?: string;
}

const BookingDate = ({
  onClick,
  className = 'date',
  children,
  testId = 'booking-date'
}: IProps) => (
  <DateWrapper onClick={onClick} className={className} data-testid={testId}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4H17V3C17 2.4 16.6 2 16 2C15.4 2 15 2.4 15 3V4H9V3C9 2.4 8.6 2 8 2C7.4 2 7 2.4 7 3V4H4C2.9 4 2 4.9 2 6V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V6C22 4.9 21.1 4 20 4ZM7 6V7C7 7.6 7.4 8 8 8C8.6 8 9 7.6 9 7V6H15V7C15 7.6 15.4 8 16 8C16.6 8 17 7.6 17 7V6H20V10H4V6H7ZM4 20V12H20V20H4Z" fill="#666666"/>
    </svg>
    { children }
  </DateWrapper>
);

export default BookingDate;
