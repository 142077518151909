import React, { useEffect } from 'react';

import Suggester from 'src/comps/Molecules/Suggester';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSuggestionsRequestAction,
  resetSuggestionsAction,
} from 'src/effects/Suggester/actions';
import { getSuggestions } from 'src/effects/Suggester/selectors';

import { SuggestionType, SearchMode } from 'src/types/search';
import { useMarket } from 'src/hooks/useMarket';
import { useSearch } from 'src/hooks/useSearch';

interface ISuggesterProps {
  readonly initialValue?: SuggestionType;
  readonly mode: SearchMode;
  readonly className?: string;
}

interface ISuggestionFromApi {
  id: string;
  coordinates: string;
  country: string;
  slug: string;
  type: string;
  text: string;
  description: string;
  stars: number;
  citySlug: string;
}

const mapSuggestonsToUpperCase = (suggestion: ISuggestionFromApi) => ({
  ID: suggestion.id,
  Coordinate: suggestion.coordinates,
  Country: suggestion.country,
  Slug: suggestion.slug,
  Type: suggestion.type,
  Text: suggestion.text,
  Description: suggestion.description,
  Stars: suggestion.stars,
  CitySlug: suggestion.citySlug,
});

function SuggesterConnected({ initialValue, mode, className = '' }: ISuggesterProps) {
  const dispatch = useDispatch();
  const { language, market } = useMarket();
  const { draftLocation, setLocation } = useSearch();

  useEffect(() => {
    if (initialValue?.ID && !draftLocation?.ID) {
      setLocation(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suggestions = useSelector(getSuggestions);

  // Connect to provider to get the respective values
  const handleChangeInput = (text: string) => {
    if (!text) {
      dispatch(resetSuggestionsAction());
    }

    if (text && text.length > 1) {
      dispatch(fetchSuggestionsRequestAction({ language, market, search: text }));
    }
  };

  const handleOnSelect = (suggestion: SuggestionType | null) => {
    setLocation(suggestion);
  };

  return (
    <Suggester
      key={draftLocation?.ID}
      mode={mode}
      suggestion={draftLocation}
      suggestions={suggestions.map(mapSuggestonsToUpperCase)}
      onChangeInput={handleChangeInput}
      onSelect={handleOnSelect}
      className={className}
    />
  );
}

export default SuggesterConnected;
