import React, { ChangeEvent, FocusEvent, useState, useRef, useEffect } from "react";
import { IconWrapper, InputElement, InputWrapper, Label, LabelText } from "./styles";

import { useIntl } from "react-intl";

import InputError from "./InputError";

export type InputTypes = 'text' | 'number' | 'search' | 'email' | 'tel' | 'checkbox' | 'labeled' | 'password';

export interface InputProps {
  autoComplete?: 'on' | 'off',
  className?: string,
  dataTestId?: string,
  disabled?: boolean,
  errors?: string[],
  icon?: React.ReactNode,
  id?: string,
  label: string,
  name?: string,
  onBlur?: (value: string) => void,
  onChange?: (value: string) => void,
  onFocus?: (value: string) => void,
  type?: string,
  value?: string,
  min?: number,
  max?: number,
  required?: boolean,
  placeholder?: string,
};

function Input(props: InputProps) {
  const {
    label,
    icon,
    type = 'text',
    disabled = false,
    value,
    onFocus,
    onBlur,
    onChange,
    errors = [],
    min,
    max,
    required = false,
    dataTestId = null,
    ...rest
  } = props;

  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setFocus] = useState(false);
  const [minError, setMinError] = useState(value && min && value.length < min);
  const [internalValue, setInternalValue] = useState(value);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (internalValue === value) return;

    setInternalValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const validate = (newValue: string) => {
    if (min) {
      setMinError(newValue.length < min)
    }
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    if (onFocus) {
      const { value: newValue } = event.target;
      onFocus(newValue.trim())
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setFocus(false)
    setShowErrors(true)
    if (onBlur) {
      const { value: newValue } = event.target;
      onBlur(newValue.trim())
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange && !disabled) {
      const { value: newValue } = event.target;
      if (!max || max && newValue.length <= max) {
        validate(newValue.trim())
        onChange(newValue.trim())
        setInternalValue(newValue)
      }
      setShowErrors(true)
    }
  }

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  let totalErrors = [...errors];

  if (minError) {
    totalErrors = [...totalErrors, intl.formatMessage({ id: 'input.valid-min' }, { number: min })]
  }

  if (showErrors && required && !internalValue) {
    totalErrors = [...totalErrors, intl.formatMessage({ id: 'validation.required' })]
  }

  return (
    <>
      <InputWrapper
        withIcon={Boolean(icon)}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        hasError={totalErrors.length > 0}
      >
        {icon && (<IconWrapper data-testid="input-icon-wrapper" onClick={handleIconClick}>
          {icon}
        </IconWrapper>
        )}
        <Label>
          <LabelText
            withIcon={!!icon}
            isTransitioned={(hasFocus || Boolean(internalValue))}
            disabled={disabled}
          >
            {label}
          </LabelText>
          <InputElement
            ref={inputRef}
            data-testid={dataTestId}
            type={type}
            disabled={disabled}
            {...rest}
            value={internalValue}
            onChange={handleChange} />
        </Label>
      </InputWrapper>
      {totalErrors.length > 0 && <InputError errors={totalErrors} />}
    </>
  )
}

export default Input;
