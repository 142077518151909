import { InputWrapper, LabelText } from "src/comps/Atom/Inputs/Input/styles";
import { ButtonResetBase } from "src/comps/ButtonReset/styles";
import styled from "styled-components";

interface SuggesterBorderLessWrapperProps {
  hasFocus: boolean;
}

export const SuggesterBorderLessWrapper = styled.div<SuggesterBorderLessWrapperProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 6px 8px;
  background: ${props => props.theme.colors.grey8};
  border-radius: 100px;
  border: 2px solid transparent;

  ${InputWrapper} {
    width: 100%;
    padding: 0;
    border: none;
    background: none;
    height: 28px;
    right: 8px;

    ${LabelText} {
      display: none;
    }

    &:hover {
      border: none;
    }

    input {
      padding: 0;
      padding-left: 5px;
    }

    svg {
      margin: 0 0 0 8px;
    }
  }

  ${ButtonResetBase} {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 8px;
  }

  &:hover{
    border-color: ${props => props.theme.colors.grey2};
  }

  ${({ theme, hasFocus }) => hasFocus && `
    border-color: ${theme.colors.grey2};
  `}  
`;
