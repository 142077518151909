import React from 'react';

import Stars from 'client/components/Stars';

import { getSuggestionIcon } from 'src/comps/SuggesterItem/utils';
import { LocationInfo, LocationName, SuggestionBase, TextContent } from './styles';
import { SuggestionType } from 'src/types/search';
import { noop } from 'lodash';

interface ISuggestionItemProps {
  item?: SuggestionType;
  onClick?: () => void;
}

const Suggestion = ({ item, onClick = noop }: ISuggestionItemProps) => {
  if (!item) {
    return null;
  }

  const LocationTypeIcon = getSuggestionIcon(item.Type);

  return (
    <SuggestionBase data-testid="suggester-option" onClick={onClick}>
      <LocationTypeIcon size={20} />

      <TextContent>
        <LocationName>
          <p>{item.Text}</p>
          {item.Type === 'hotel' && <Stars stars={item.Stars} color="grey1" />}
        </LocationName>

        <LocationInfo>{item.Description}</LocationInfo>
      </TextContent>
    </SuggestionBase>
  );
};

export default Suggestion;
