import { InputHTMLAttributes } from 'react';
import styled, { css, Theme } from 'styled-components';

interface InputWrapperProps extends InputHTMLAttributes<HTMLInputElement> {
  withIcon: boolean,
  disabled: boolean,
  hasError?: boolean,
  theme: Theme,
}

interface LabelTextProps {
  isTransitioned: boolean,
  withIcon: boolean,
  theme: Theme,
  disabled: boolean,
}

export const getInputBorderColor = (props: InputWrapperProps) => {
  if (props.hasError) {
    return props.theme.colors.failure;
  }

  return props.theme.colors.grey5;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  height: 48px;
  border: 2px solid ${(props) => getInputBorderColor(props)};
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.disabled ? props.theme.colors.grey5 : ''};

  &:hover {
    border: 2px solid ${(props) => !props.disabled ? props.theme.colors.grey3 : 'transparent'};
  }

  input {
    padding: 12px 16px 12px ${(props) => props.withIcon ? '8px' : '16px'};
  }

  svg {
    margin: 9px 0px 9px 16px;
    fill: ${props => props.theme.colors.grey2};
    flex: 0 0 auto;
  }
`;

export const InputElement = styled.input`
  width: 100%;
  font-weight: 700;
  border: none;
  border-radius: 6px;

  &:focus {
    outline: none;
  }

  &:disabled {    
    color: ${props => props.theme.colors.grey2};
  }
`;

export const IconWrapper = styled.span`
  flex: 0 0 auto;
  text-align: center;
`;

export const Label = styled.label`
  display: inline-block;
  font-family: ${props => props.theme.fonts.families.primary};
  color: ${props => props.theme.colors.grey3};
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 100%;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const labelWithiconTransitioned = css`
  transform: translate(-40px, -35px) scale(0.75) !important;
  padding: 3px 5px;
`;

const labelTransitioned = css`
  transform: translate(-15px, -35px) scale(0.75) !important;
  padding: 3px 5px;
`;

const getLabelMaxWidth = ({ withIcon, isTransitioned }: LabelTextProps) => {
  if (isTransitioned) {
    return `calc(100% + ${withIcon ? '80px' : '20px'})`
  }
  return `calc(100% - ${withIcon ? '60px' : '35px'})`
}

export const LabelText = styled.span`
  white-space: nowrap;
  position: absolute;
  top: 50%;
  transform-origin: left;
  left: ${props => props.withIcon ? "45px" : '20px'};
  transform: ${(props: LabelTextProps) => props.withIcon ? "translate(0px, -50%)" : "translateY(-50%)"};
  pointer-events: none;
  transition: transform 0.1s, background-color 0.1s;

  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: ${props => getLabelMaxWidth(props)};

  ${(props: LabelTextProps) =>
    props.isTransitioned && (!props.withIcon ? labelTransitioned : labelWithiconTransitioned)
  }

  background-color: ${(props: LabelTextProps) => !props.disabled || props.isTransitioned ? props.theme.colors.grey8 : 'transparent'};
`;
