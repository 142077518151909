import React, {
  createContext,
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { format, addDays } from 'date-fns';
import { useMarket } from 'src/hooks/useMarket';
import { getSearchParamsLocalStorage } from 'lib/storage';

import {
  AccommodationOptions,
  FacilitiesOptions,
  Room,
  SearchType,
  starsOptions,
  StarsOptions,
  PriceRangeType,
  SuggestionType,
  SearchStatus,
  SearchActions,
  LocationTypes,
  BoxShapeType,
} from 'src/types/search';

import {
  getMinBookingDate,
  getDateRange,
  getRoomsFromUrlParams,
  getRoomsFromLocalParams,
  omitQueryParams,
  updateSearchStatus,
  replaceQueryParams,
  isProperty,
  isGeo,
  boxShapeToArray,
  getLocationFromUrlParams,
  hasChangeSearch,
  getUniqueKeyFromLocation,
} from './utils';

import { RatingOptions, SortOptions } from 'src/pages/api/trpc/search/schema';

import type { ParsedUrlQuery } from 'querystring';
import { PathParams } from 'src/types/results';
import { useBusiness } from 'src/hooks/useBusiness';
import { DEFAULT_SOURCE } from 'src/constants/sources';

const SearchContext = createContext<SearchType | null>(null);

const ratingOptions = Object.values(RatingOptions);
const getValidRatings = (values: string[]) =>
  (values as RatingOptions[]).filter(rating => ratingOptions.includes(rating));

const sortOptionDefault = SortOptions.Recommended;

const accommodationsOptions = Object.values(AccommodationOptions);
const getValidAccommodations = (values: string[]) =>
  (values as AccommodationOptions[]).filter(accommodation =>
    accommodationsOptions.includes(accommodation)
  );

const facilitiesOptions = Object.values(FacilitiesOptions);
const getValidFacilities = (values: string[]) =>
  (values as FacilitiesOptions[]).filter(facility => facilitiesOptions.includes(facility));

const getValidStars = (values: string[]) =>
  (values as StarsOptions[]).filter(star => starsOptions.includes(star));

const PAXES_DEFAULT_ADULTS = '2';
const PAXES_DEFAULT_CHILDREN = '';

const SearchProvider = ({
  children,
  value,
  location,
}: {
  children: ReactElement;
  value?: SearchType | null;
  location: SuggestionType | null;
}) => {
  const { daysOfBooking, inanitionDays, market, defaultBookingDays } = useMarket();

  if (value === null) {
    throw new Error('SearchContext provider can not have a null value');
  }

  const router = useRouter();
  const currentLocalSearch = useMemo(() => getSearchParamsLocalStorage(), []);

  // At beggining, we set the search status to fetching to fetch data for the first time
  const [searchStatus, setSearchStatus] = useState<SearchStatus>(SearchStatus.Idle);

  const initialLocation = value?.location ?? location;
  const [internalLocation, setInternalLocation] = useState<SuggestionType | null>(
    initialLocation ?? null
  );

  const initialLocationKey = initialLocation?.Slug ? getUniqueKeyFromLocation(initialLocation) : '';
  const locationHistory = useRef(new Map([[initialLocationKey, initialLocation]]));

  const locationFromUrlParams = useMemo(() => {
    const locationFromUrl = getLocationFromUrlParams(router.query as unknown as PathParams);
    const locationKey = locationFromUrl ? getUniqueKeyFromLocation(locationFromUrl) : '';
    const locationFromHistory = locationHistory.current.get(locationKey);
    return locationFromHistory ?? locationFromUrl ?? initialLocation;
  }, [router.query, locationHistory, initialLocation]);

  const [page, setPage] = useState(1);

  const { affiliate } = useBusiness();

  const today = new Date();
  const minBookingDateObject = getMinBookingDate(
    {
      year: today.getFullYear(),
      month: today.getMonth(),
      date: today.getDate(),
    },
    {
      year: today.getUTCFullYear(),
      month: today.getUTCMonth(),
      date: today.getUTCDate(),
    }
  );

  const { year, month, date } = minBookingDateObject;

  const minBookingDate = useMemo(
    () => addDays(new Date(year, month, date), inanitionDays),
    [date, month, year, inanitionDays]
  );

  const { checkin, checkout } = getDateRange({
    checkIn: router?.query.checkin ?? currentLocalSearch?.checkIn,
    checkOut: router?.query.checkin ? router?.query.checkout ?? '' : currentLocalSearch?.checkOut,
    minBookingDate,
    daysOfBooking: defaultBookingDays,
  });

  const [internalDates, setInternalDates] = useState({
    checkIn: checkin,
    checkOut: checkout,
  });

  const { adults: adultsParam, children: childrenParam } = router.query;
  const { adults: adultsLocal, children: childrenLocal } = currentLocalSearch;

  const rooms =
    !adultsParam && adultsLocal
      ? getRoomsFromLocalParams(adultsLocal, childrenLocal)
      : getRoomsFromUrlParams({
          adults: adultsParam ?? PAXES_DEFAULT_ADULTS,
          children: childrenParam ?? PAXES_DEFAULT_CHILDREN,
        });

  // check min date with utc times and update
  const setDates = useCallback(
    ({ checkIn, checkOut }) => {
      const checkOutOrMinDate = checkOut || addDays(checkIn, defaultBookingDays);
      setInternalDates({
        checkIn,
        checkOut: checkOutOrMinDate,
      });
    },

    [defaultBookingDays]
  );

  const [internalRooms, setInternalRooms] = useState(rooms);

  const setPaxes = useCallback((newRooms: Room[]) => {
    setInternalRooms(newRooms);
  }, []);

  const [initialSearch] = useState({
    location: internalLocation?.ID,
    checkin: internalDates.checkIn.getTime(),
    checkout: internalDates.checkOut.getTime(),
    rooms: internalRooms,
  });

  const { 'min-price': minPrice } = router.query;
  const { 'max-price': maxPrice } = router.query;

  const priceRange = useMemo(() => {
    if (!minPrice) return undefined;
    if (minPrice && !maxPrice)
      return {
        min: Number(minPrice),
      };
    if (Number(maxPrice) < Number(minPrice))
      return {
        min: Number(minPrice),
      };
    return {
      min: Number(minPrice),
      max: Number(maxPrice),
    };
  }, [minPrice, maxPrice]);

  const setPriceRange = useCallback(
    async (range: PriceRangeType) => {
      const params = {
        ...(range?.min && { 'min-price': `${range?.min}` }),
        ...(range?.max && { 'max-price': `${range?.max}` }),
      };
      await replaceQueryParams(router, params);
    },
    [router]
  );

  const resetPriceRange = useCallback(async () => {
    await omitQueryParams(router, ['min-price', 'max-price']);
  }, [router]);

  const { ratings: ratingsQuery } = router.query;
  const ratings = useMemo(() => {
    if (!ratingsQuery) return new Set<RatingOptions>();
    if (Array.isArray(ratingsQuery)) return new Set<RatingOptions>();

    const values = ratingsQuery.split(',');
    if (values.length === 0) return new Set<RatingOptions>();

    const validOptions = getValidRatings(values);
    return new Set<RatingOptions>(validOptions);
  }, [ratingsQuery]);

  const resetRatings = useCallback(async () => {
    await omitQueryParams(router, ['ratings']);
  }, [router]);

  const setRatings = useCallback(
    async (updatedRatings: Set<RatingOptions>) => {
      if (updatedRatings.size === 0) {
        await resetRatings();
        return;
      }
      const newRatings = Array.from(updatedRatings);

      // We are converting into a comma separated string for the options
      // otherwise next will try to make several query params with the same
      // which makes the serialization harder.
      await replaceQueryParams(router, { ratings: newRatings.join(',') });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const { facilities: facilitiesQuery } = router.query;
  const facilities = useMemo(() => {
    if (!facilitiesQuery) return new Set<FacilitiesOptions>();
    if (Array.isArray(facilitiesQuery)) return new Set<FacilitiesOptions>();

    const values = facilitiesQuery.split(',');
    if (values.length === 0) return new Set<FacilitiesOptions>();

    const validOptions = getValidFacilities(values);
    return new Set<FacilitiesOptions>(validOptions);
  }, [facilitiesQuery]);

  const resetFacilities = useCallback(async () => {
    const newQuery = omit(router.query, 'facilities');
    await router.replace(
      {
        query: {
          ...newQuery,
        },
      },
      undefined,
      { shallow: true }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const setFacilities = useCallback(
    async (updatedFacilities: Set<FacilitiesOptions>) => {
      if (updatedFacilities.size === 0) {
        await resetFacilities();
        return;
      }
      const newFacilities = Array.from(updatedFacilities);
      await replaceQueryParams(router, { facilities: newFacilities.join(',') });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const { stars: starsQuery } = router.query;
  const stars = useMemo(() => {
    if (!starsQuery) return new Set<StarsOptions>();
    if (Array.isArray(starsQuery)) return new Set<StarsOptions>();

    const values = starsQuery.split(',');
    if (values.length === 0) return new Set<StarsOptions>();

    const validOptions = getValidStars(values);
    return new Set<StarsOptions>(validOptions);
  }, [starsQuery]);

  const resetStars = useCallback(async () => {
    await omitQueryParams(router, ['stars']);
  }, [router]);

  const setStars = useCallback(
    async (updatedStars: Set<StarsOptions>) => {
      if (updatedStars.size === 0) {
        await resetStars();
        return;
      }
      const newStars = Array.from(updatedStars);
      await replaceQueryParams(router, { stars: newStars.join(',') });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const { accommodations: accommodationQuery } = router.query;
  const accommodations = useMemo(() => {
    if (!accommodationQuery) return new Set<AccommodationOptions>();
    if (Array.isArray(accommodationQuery)) return new Set<AccommodationOptions>();

    const values = accommodationQuery.split(',');
    if (values.length === 0) return new Set<AccommodationOptions>();

    const validOptions = getValidAccommodations(values);
    return new Set<AccommodationOptions>(validOptions);
  }, [accommodationQuery]);

  const resetAccommodations = useCallback(async () => {
    await omitQueryParams(router, ['accommodations']);
  }, [router]);

  const setAccommodations = useCallback(
    async (updatedAccommodations: Set<AccommodationOptions>) => {
      if (updatedAccommodations.size === 0) {
        await resetAccommodations();
        return;
      }
      const newAccommodations = Array.from(updatedAccommodations);
      await replaceQueryParams(router, { accommodations: newAccommodations.join(',') });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const filtersParams = [
    'min-price',
    'max-price',
    'stars',
    'ratings',
    'family',
    'accommodations',
    'facilities',
  ];

  const resetFilters = useCallback(async () => {
    await omitQueryParams(router, filtersParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const setFilters = useCallback(
    async (updatedFilters: Record<string, any>) => {
      const { range, familyFriendly, ...rest } = updatedFilters;
      const params: ParsedUrlQuery = {};

      if ((range as PriceRangeType)?.max) {
        params['max-price'] = `${range.max}`;
      }

      if ((range as PriceRangeType)?.min) {
        params['min-price'] = `${range.min}`;
      }

      if (familyFriendly) {
        params.family = 'true';
      }

      Object.entries(rest).forEach(([filterName, filterValue]) => {
        if (filterValue.size > 0) {
          params[filterName] = Array.from(filterValue).join(',');
        }
      });

      const currentParams = omit(router.query, filtersParams);
      await replaceQueryParams(router, params, currentParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const getValidSortBy = (sortValue: SortOptions) => {
    const foundSortOption = Object.values(SortOptions).find(
      sortByOption => sortByOption === sortValue
    );

    return foundSortOption;
  };

  const { highlightedProperty } = router.query;

  const { sort: sortByQuery } = router.query;
  const sortBy = useMemo(() => {
    if (!sortByQuery) {
      return SortOptions.Recommended;
    }

    const isValidSort = getValidSortBy(sortByQuery as SortOptions);

    return isValidSort ?? sortOptionDefault;
  }, [sortByQuery]);

  const setSortBy = useCallback(
    async (newSortBy: SortOptions) => {
      await replaceQueryParams(router, { sort: newSortBy });
    },
    [router]
  );

  const { family: familyQuery } = router.query;
  const familyFriendly = useMemo(() => {
    return (familyQuery as string) === 'true';
  }, [familyQuery]);

  const setFamilyFriendly = useCallback(
    async (newFamilyFriendly: boolean) => {
      const params = { ...(newFamilyFriendly && { family: 'true' }) };
      const currentParams = omit(router.query, ['family']);
      await replaceQueryParams(router, params, currentParams);
    },
    [router]
  );

  const setLocation = useCallback((newLocation: SuggestionType | null) => {
    setInternalLocation(newLocation);
    if (newLocation?.Slug) {
      const key = getUniqueKeyFromLocation(newLocation);
      locationHistory.current.set(key, newLocation);
    }
  }, []);

  const nextPage = useCallback(async () => {
    setPage(page + 1);
  }, [page, setPage]);

  const resetPage = useCallback(async () => {
    setPage(1);
  }, [setPage]);

  const changeState = useCallback(
    async (action: SearchActions) => {
      setSearchStatus(updateSearchStatus(searchStatus, action));
    },
    [searchStatus]
  );

  useEffect(() => {
    const handleRouterChange = () => {
      changeState(SearchActions.Fetch);
    };
    router.events?.on('routeChangeComplete', handleRouterChange);

    return () => {
      router.events?.off('routeChangeComplete', handleRouterChange);
    };
  }, [router, changeState]);

  useEffect(() => {
    setInternalLocation(locationFromUrlParams);
  }, [locationFromUrlParams]);

  const searchAffiliate = useMemo(() => {
    const actualSearch = {
      location: internalLocation?.ID,
      checkin: internalDates.checkIn.getTime(),
      checkout: internalDates.checkOut.getTime(),
      rooms: internalRooms,
    };

    const { source } = router.query;

    if (!hasChangeSearch(initialSearch, actualSearch) && affiliate) {
      const sourceValue = source ?? DEFAULT_SOURCE;
      return { affiliate, source: sourceValue } as { affiliate: string; source?: string };
    }

    return { affiliate, source: DEFAULT_SOURCE };
  }, [router, initialSearch, internalLocation, internalDates, internalRooms, affiliate]);

  const queryParams = useMemo(
    () => ({
      checkin: format(internalDates.checkIn, 'yyyy-MM-dd'),
      checkout: format(internalDates.checkOut, 'yyyy-MM-dd'),
      children: internalRooms.map(room => room.children.join(',')).join('!'),
      adults: internalRooms.map(room => room.adults).join(','),
    }),
    [internalDates, internalRooms]
  );

  const geoQueryParams = useMemo(() => {
    return internalLocation && isGeo(internalLocation.Type)
      ? {
          type: LocationTypes.Geo,
          boxshape: boxShapeToArray(internalLocation?.BoxShape as BoxShapeType),
        }
      : {};
  }, [internalLocation]);

  const searchUrl = useMemo(() => {
    if (!internalLocation) {
      // this never whould happen because we are disable the search button
      return {
        href: {
          pathname: router.asPath,
          query: router.query,
        },
        shallow: false,
      };
    }

    if (isProperty(internalLocation.Type)) {
      return {
        href: {
          pathname: `/hotel/[country]/[slug]`,
          query: {
            ...queryParams,
            country: internalLocation.Country,
            slug: `${internalLocation.Slug}_${internalLocation?.CitySlug}`,
          },
        },
        shallow: false,
      };
    }

    const filters = pick(router.query, [
      'min-price',
      'max-price',
      'stars',
      'ratings',
      'accommodations',
      'facilities',
      'sort',
      'family',
    ]);

    if (isGeo(internalLocation.Type)) {
      return {
        href: {
          pathname: `/search`,
          query: {
            ...queryParams,
            ...filters,
            ...geoQueryParams,
          },
        },
        shallow: true,
      };
    }

    return {
      href: {
        pathname: `/search`,
        query: {
          country: internalLocation.Country,
          type: internalLocation.Type,
          slug: internalLocation.Slug,
          ...queryParams,
          ...(internalLocation?.ID === location?.ID && {
            ...filters,
          }),
        },
      },
      shallow: true,
    };
  }, [router, internalLocation, queryParams, geoQueryParams, location]);

  const oldSearchUrl = useMemo(() => {
    if (!internalLocation) {
      // this never whould happen because we are disable the search button
      return {
        href: {
          pathname: router.asPath,
          query: router.query,
        },
        shallow: false,
      };
    }

    if (isProperty(internalLocation.Type)) {
      return {
        href: {
          pathname: `/hotel/[country]/[slug]`,
          query: {
            ...queryParams,
            country: internalLocation.Country,
            slug: `${internalLocation.Slug}_${internalLocation?.CitySlug}`,
          },
        },
        shallow: false,
      };
    }

    const filters = pick(router.query, ['checkin', 'checkout', 'children', 'adults']);

    return {
      href: {
        pathname: `/[country]/[type]/[slug]`,
        query: {
          country: internalLocation.Country,
          type: internalLocation.Type,
          slug: internalLocation.Slug,
          ...queryParams,
          ...(internalLocation?.ID === location?.ID && {
            ...filters,
          }),
        },
      },
      shallow: true,
    };
  }, [router, internalLocation, queryParams, location]);

  const searchValue = useMemo(
    () => ({
      checkin,
      draftCheckin: internalDates.checkIn,
      checkout,
      draftCheckout: internalDates.checkOut,
      setDates,
      rooms,
      draftRooms: internalRooms,
      setPaxes,
      market,
      minBookingDate,
      daysOfBooking,
      priceRange,
      setPriceRange,
      resetPriceRange,
      familyFriendly,
      setFamilyFriendly,
      facilities,
      setFacilities,
      resetFacilities,
      accommodations,
      setAccommodations,
      resetAccommodations,
      stars,
      setStars,
      resetStars,
      sortBy,
      setSortBy,
      ratings,
      setRatings,
      resetRatings,
      location: locationFromUrlParams,
      draftLocation: internalLocation,
      setLocation,
      searchStatus,
      setSearchStatus,
      changeState,
      page,
      setPage,
      nextPage,
      resetPage,
      highlightedProperty: parseInt(highlightedProperty as string, 10),
      setFilters,
      resetFilters,
      searchUrl,
      oldSearchUrl,
      searchAffiliate,
    }),
    [
      checkin,
      checkout,
      internalDates,
      setDates,
      rooms,
      internalRooms,
      setPaxes,
      market,
      minBookingDate,
      daysOfBooking,
      priceRange,
      setPriceRange,
      resetPriceRange,
      familyFriendly,
      setFamilyFriendly,
      facilities,
      setFacilities,
      resetFacilities,
      accommodations,
      setAccommodations,
      resetAccommodations,
      stars,
      setStars,
      resetStars,
      sortBy,
      setSortBy,
      ratings,
      setRatings,
      resetRatings,
      locationFromUrlParams,
      internalLocation,
      setLocation,
      searchStatus,
      setSearchStatus,
      changeState,
      page,
      setPage,
      nextPage,
      resetPage,
      highlightedProperty,
      setFilters,
      resetFilters,
      searchUrl,
      oldSearchUrl,
      searchAffiliate,
    ]
  );

  return <SearchContext.Provider value={searchValue}>{children}</SearchContext.Provider>;
};

export { SearchContext, SearchProvider };
