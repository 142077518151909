import styled from 'styled-components';
import { colors, helpers } from 'client/styles';

export const SuggestionBase = styled.li`
  align-items: center;
  display: flex;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.grey5};

  :hover,
  [data-highlighted] & {
    background-color: ${colors.yellow2};
  }

  > svg {
    fill: ${colors.grey3};
    flex: 0 0 auto;
    margin: 0 10px 0 5px;
  }
`;

export const LocationName = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;

  > p {
    color: ${colors.grey1};
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    margin-right: 8px;
    ${helpers.ellipsis};
  }

  svg {
    fill: ${colors.grey2};
  }
`;

export const LocationInfo = styled.p`
  color: ${colors.grey2};
  display: block;
  font-size: 13px;
  line-height: 1.5;
  margin-right: 10px;
  ${helpers.ellipsis};
`;

export const TextContent = styled.div`
  overflow: hidden;
`;
