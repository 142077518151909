import React from "react";

import Paxes from "src/comps/Molecules/Paxes";
import { useSearch } from "src/hooks/useSearch";
import { Room, SearchMode } from "src/types/search";

interface IProps {
  mode: SearchMode;
}

function PaxesConnected({ mode }: IProps) {
  const { draftRooms, setPaxes } = useSearch();

  const handleOnChange = (paxes: Room[]) => {
    setPaxes(paxes);
  }

  return (
    <Paxes initialValue={draftRooms} onChange={handleOnChange} mode={mode} />
  )
}

export default PaxesConnected;
