import styled, { css } from "styled-components";
import { SearchMode } from "src/types/search";

interface IProps {
  mode: SearchMode;
}

export const PaxSelectorWrapper = styled.div<IProps>`
  padding-bottom: 20px;

  ${({ mode }) => mode === SearchMode.Full && css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  `};
`;

export const PaxesMobileHeader = styled.div<IProps>`
  display: none;

  & > svg {
    cursor: pointer;
  }

  ${({ mode }) => mode === SearchMode.Full && css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1.1em;
    font-weight: 700;
    padding-left: 1em;
    color: ${props => props.theme.colors.grey2};
    padding: 20px;
  `};
`;

export const LinkButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;  
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.brand1};
`;

export const PaxSelectorContent = styled.div<IProps>`
  ${({ mode }) => mode === SearchMode.Full && css`
    flex: 1;
    overflow: auto;
    width: 100%;
  `};
`;

export const PaxesFooter = styled.div`
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey5};
  padding-top: 20px;  
`;
