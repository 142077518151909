import React from 'react';
import { PillWrapper } from './styles';

interface IPillProps {
  leftIcon?: React.ReactNode;
  label: string | React.ReactNode;
  rightIcon?: React.ReactNode;
  selected?: boolean;
  className?: string;
  onClick?: () => void;
}

function Pill({
  leftIcon = null,
  label,
  rightIcon = null,
  selected = false,
  className = 'pill',
  onClick,
}: IPillProps) {
  return (
    <PillWrapper onClick={onClick} selected={selected} className={className}>
      {leftIcon}
      <span>{label}</span>
      {rightIcon}
    </PillWrapper>
  );
}

export default Pill;
