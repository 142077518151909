import React from 'react';

import { useSearch } from 'src/hooks/useSearch';
import DatePicker from 'src/comps/Molecules/DatePicker';
import { SearchMode } from 'src/types/search';

interface IProps {
  mode: SearchMode;
}

const ConnectedDatePicker = ({ mode }: IProps) => {
  const { draftCheckin, draftCheckout, minBookingDate, setDates } = useSearch();

  return (
    <DatePicker
      initialValue={{ checkIn: draftCheckin, checkOut: draftCheckout }}
      onChange={setDates}
      firstAvailableDate={minBookingDate}
      mode={mode}
    />
  )
}

export default ConnectedDatePicker;
