import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { addMonths, differenceInCalendarMonths, isSameMonth } from 'date-fns';

import { getWeekDays, getWeekStartsOn } from 'client/utils';
import { useMarket } from 'src/hooks/useMarket';
import { useDevice } from 'src/hooks/useDevice';
import DateRangeCalendar from 'client/components/DatePicker/Calendar';
import { WeekDays } from 'client/components/DatePicker/styles';
import IconArrowDown from 'client/icons/ArrowDown';

import { CalendarBase, CalendarWrapper, AddMonths } from './styles';
import { BreakpointSizes } from 'src/types/device';

interface IDateRange {
  checkIn: Date;
  checkOut: Date;
}

interface IDates {
  startDate: Date;
  endDate: Date;
}

interface IProps {
  startDate: Date;
  endDate: Date | null;
  onChange: ({ checkIn, checkOut }: IDateRange) => void;
  minDate: Date;
  isFocusedCheckIn: boolean;
}

const Calendar = ({ startDate, endDate, onChange, minDate, isFocusedCheckIn }: IProps) => {
  const intl = useIntl();
  const { market, minBookingDate } = useMarket();
  const { activeBreakpoint } = useDevice();
  const isWide = activeBreakpoint >= BreakpointSizes.L;

  const [calendarMonths, setCalendarMonths] = useState<Date[]>([]);
  const [checkInMonth, setCheckInMonth] = useState<HTMLElement>();

  useEffect(() => {
    const months = [];
    if (isWide) {
      months.push(startDate);
      months.push(addMonths(startDate, 1));
    } else {
      const deltaMonths = differenceInCalendarMonths(startDate, minDate);

      [...Array(deltaMonths + 1).keys()].forEach(index => months.push(addMonths(minDate, index)));
      months.push(addMonths(startDate, 1));
      months.push(addMonths(startDate, 2));
    }
    setCalendarMonths(months);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWide]);

  function addMoreMonths() {
    const lastVisibleMonthDate = calendarMonths[calendarMonths.length - 1];
    const monthsToAdd = Array.from({ length: 3 }, (item, index) =>
      addMonths(lastVisibleMonthDate, index + 1)
    );
    setCalendarMonths([...calendarMonths, ...monthsToAdd]);
  }

  function navigateMonths(direction: string) {
    const updatedMonths = calendarMonths.map(month =>
      direction === 'prev' ? addMonths(month, -1) : addMonths(month, 1)
    );

    setCalendarMonths(updatedMonths);
  }

  function handleDatesChange({ startDate: checkIn, endDate: checkOut }: IDates) {
    onChange({ checkIn, checkOut });
  }

  const weekStartsOn = getWeekStartsOn(market);
  const weekDays = getWeekDays(weekStartsOn);

  const setScrollableArea = useCallback(() => {
    if (!isWide && checkInMonth && checkInMonth.scrollIntoView) {
      checkInMonth.scrollIntoView()
    }
  }, [checkInMonth, isWide]);

  function scrollToMonth(node : HTMLElement) {
    setCheckInMonth(node);
  }

  return (
    <CalendarBase data-testid="calendar">
      <WeekDays id="small" data-testid="week-days-small">
        {weekDays.map(day => (
          <li key={day.toString()}>{intl.formatDate(day, { weekday: 'narrow' })}</li>
        ))}
      </WeekDays>
      <CalendarWrapper ref={setScrollableArea}>
        {calendarMonths.map((month, index) => (
          <DateRangeCalendar
            index={0}
            key={month.toString()}
            isFocusedCheckIn={isFocusedCheckIn}
            isCheckInMonth={!checkInMonth && isSameMonth(startDate, month)}
            scrollToMonth={scrollToMonth}
            onNavigate={navigateMonths}
            onSelect={handleDatesChange}
            position={index}
            selectedDates={{ startDate, endDate }}
            variant={isWide ? 'horizontal' : 'vertical'}
            market={market}
            currentDate={month}
            minBookingDate={minBookingDate}
          />
        ))}
        <AddMonths type="button" onClick={addMoreMonths}>
          <IconArrowDown />
        </AddMonths>
      </CalendarWrapper>
    </CalendarBase>
  );
};

export default Calendar;
