import { Room } from "src/types/search";

export function getRooms(paxes: Room[]) {
  const rooms = paxes.length;

  return rooms;
}

export function getAdults(paxes: Room[]) {
  const adultsCount = paxes.reduce((acc, cur) => acc + cur.adults, 0);

  return adultsCount;
}

export function getChildren(paxes: Room[]) {
  const childrenCount = paxes
    .reduce((acc, cur) => acc + cur.children.length, 0);

  return childrenCount;
}

export function getPaxes(initialValue: Room[]) {
  const rooms = getRooms(initialValue);
  const adults = getAdults(initialValue);
  const children = getChildren(initialValue);

  const paxes = {
    rooms,
    adults,
    children,
  }

  return paxes;
}
