import React, { useCallback, useEffect, useState } from 'react';

import { SuggestionType } from 'src/types/search';

import { SuggesterBorderLessWrapper } from './styles';
import Input from 'src/comps/Atom/Inputs/Input/Input';
import ButtonReset from 'src/comps/ButtonReset';
import { debounce } from 'lodash';
import IconLocation from 'client/icons/Location';
import { useIntl } from 'react-intl';
import { isGeo, isProperty } from 'src/context/search/utils';

interface ISuggesterBorderLessProps {
  suggestion?: SuggestionType | null;
  onChangeInput: (value: string) => void;
  onSelect: (value: SuggestionType | null) => void;
  hasFocus?: boolean;
}

export function SuggesterBorderLess({
  suggestion,
  onSelect,
  onChangeInput,
  hasFocus = false
}: ISuggesterBorderLessProps) {
  const intl = useIntl();
  const placeholderText = intl.formatMessage({ id: 'search.placeholder' });
  const mapAreaText = intl.formatMessage({ id: 'global.search-map-area' });
  const isGeoSuggestion = isGeo(suggestion?.Type ?? '');
  const initialSearchTerm = isGeoSuggestion ? mapAreaText : suggestion?.Description ?? '';

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const suggestionText = isProperty(suggestion?.Type) ? suggestion?.Text : suggestion?.Description;

  useEffect(() => {
    setSearchTerm( isGeoSuggestion ? mapAreaText : suggestionText ?? '');
  }, [suggestionText, mapAreaText, isGeoSuggestion]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const externalOnChangeInput = useCallback(
    debounce(
      data => {
        onChangeInput(data);
      },
      300,
      {
        leading: false,
        trailing: true,
      }
    ),
    [onChangeInput]
  );

  function handleChangeInput(text: string) {
    setSearchTerm(text);
    externalOnChangeInput(text);
  }

  function handleResetInput() {
    setSearchTerm('');
    onSelect(null);
  }

  return (
    <SuggesterBorderLessWrapper hasFocus={hasFocus} data-testid='suggester-borderless'>
      <Input
        label=''
        icon={<IconLocation size={24} />}
        onChange={handleChangeInput}
        value={searchTerm}
        placeholder={placeholderText}
      />
      <ButtonReset
        handleClick={handleResetInput}
        visible={searchTerm?.length > 0}
      />
    </SuggesterBorderLessWrapper>
  );
}
