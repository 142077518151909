import React from 'react';
import { useIntl } from 'react-intl';

import Link from 'next/link';
import Button from 'src/comps/Atom/Buttons/Button';
import SearchIcon from 'client/icons/Search';

import { useSearch } from 'src/hooks/useSearch';
import { SearchUrl } from 'src/types/search';

export interface ISearchButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullWidth: boolean;
  preventNavigation: boolean;
  searchUrl: SearchUrl;
}

const SearchButton = ({
  onClick,
  fullWidth = false,
  preventNavigation = false,
  searchUrl,
}: ISearchButtonProps) => {
  const intl = useIntl();
  const { draftLocation } = useSearch();
  const { href, shallow } = searchUrl;
  const canSearch = !!draftLocation;

  return (
    <Link href={href} passHref shallow={shallow && preventNavigation}>
      <Button
        as="a"
        size="m"
        backgroundColor="brand1"
        borderColor="brand1"
        color="grey8"
        type="button"
        disabled={!canSearch}
        leftIcon={<SearchIcon size={20} />}
        fullWidth={fullWidth}
        onClick={onClick}
        data-testid="search-button"
      >
        {intl.formatMessage({ id: 'global.search' })}
      </Button>
    </Link>
  );
};

export default SearchButton;
