import React, { ReactNode, useRef } from 'react';
import { Root, Trigger } from '@radix-ui/react-popover';
import {
  DropdownClose,
  DropdownContainer,
  DropdownContent,
  Header,
  Title,
  TriggerContainer,
} from './styles';
import Close from 'client/icons/Close';
import noop from 'lodash/noop';

interface IDropdownProps {
  ariaLabel?: string;
  title?: string;
  trigger: ReactNode;
  children: ReactNode;
  show?: boolean;
  showClose?: boolean;
  onShowChange?: (open: boolean) => void;
  onMouseLeave?: () => void;
}

/**
 * # Dropdown component
 * @param ariaLabel: string that will be used as the aria-label for the trigger
 * @param title: string that will be used as the title for the dropdown header
 * @param trigger: ReactNode that will be used as the trigger for the dropdown
 * @param children: ReactNode that will be used as the content of the dropdown
 * @param show: boolean that will be used to show the dropdown initially
 * @param onMouseLeave: function that will be fired when mouse leave dropdown content container
 *
 * @example
 *  <Dropdown ariaLabel='open filter' trigger={<Pill />} title="Price per night">
 *    <div>content</div>
 *  </Dropdown>
 *
 */
const Dropdown = ({
  ariaLabel = '',
  title = '',
  trigger,
  children,
  show = false,
  showClose = true,
  onShowChange = noop,
  onMouseLeave = noop,
}: IDropdownProps) => {
  const focusRef = useRef<HTMLDivElement>(null); 
  const handleMouseLeave = () => {
    focusRef.current?.focus();
    onMouseLeave();
  };

  return (
    <DropdownContainer>
      <Root open={show} onOpenChange={onShowChange}>
        <TriggerContainer>
          <Trigger aria-label={ariaLabel}>{trigger}</Trigger>
        </TriggerContainer>
        <DropdownContent sideOffset={5} ref={focusRef} onMouseLeave={handleMouseLeave}>
          {(showClose || title) && (
            <Header>
              <Title>{title}</Title>
              {showClose && (
                <DropdownClose aria-label="Close">
                  <Close size={24} />
                </DropdownClose>
              )}
            </Header>
          )}
          {children}
        </DropdownContent>
      </Root>
    </DropdownContainer>
  );
};

export default Dropdown;
