import styled, { css } from "styled-components";
import { SearchMode } from "src/types/search";

export const PaxesWrapper = styled.div<{ mode: SearchMode }>`
  & > div > button {
    width: 100%;
  }
  
  ${({ mode }) => mode === SearchMode.Borderless && css`
    overflow: hidden;
    & [data-radix-popper-content-wrapper] > div{
      border-color: ${({ theme }) => theme.colors.grey2};
    }
    & [data-radix-popper-content-wrapper] {
      top: 5px !important;
      z-index: 1 !important;
    }
  `};

  ${({ mode }) => mode === SearchMode.Full && css`
    margin-bottom: 16px;
    z-index: 1;
    & [data-radix-popper-content-wrapper] {
      width: 100%;
      z-index: 1 !important;
      transform: translate3d(0, 0, 0) !important;
      height: 100%;
      overflow: auto;
      min-width: unset !important;

      & > div {
        height: 100%;
        overflow: auto;        
      }
    }
  `}
`;
