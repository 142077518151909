import styled from "styled-components";

export const SuggesterReadOnlyWrapper = styled.div`
  color: ${props => props.theme.colors.grey2};
  font-family: ${props => props.theme.fonts.families.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 4px;
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
