import styled from 'styled-components';

import * as Popover from '@radix-ui/react-popover';
import { updatedMedia } from 'client/styles';

export const DropdownContainer = styled.div`
  [data-radix-popper-content-wrapper=''] {
    z-index: 20 !important;
  }
`;

export const DropdownContent = styled(Popover.Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: ${props => props.theme.colors.grey8};
  border-radius: 8px;
  width: 100%;
  min-width: 240px;
  position: relative;

  ${updatedMedia.onlyMobile} {
    max-width: '350px';
  }
  ${updatedMedia.tablet} {
    border: 2px solid ${props => props.theme.colors.grey2};
  }
`;

export const DropdownArrow = styled(Popover.Arrow)`
  fill: 'white';
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0;
`;

export const Title = styled.h3`
  font-size: 21px;
  color: ${props => props.theme.colors.grey2};
  font-weight: 700;
`;

export const DropdownClose = styled(Popover.Close)`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey3};
  cursor: pointer;
  background: none;
  padding: 4px;
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey6};
  }
  &:focus {
    box-shadow: '0 0 0 2px ${({ theme }) => theme.colors.grey3}';
  }
`;

export const TriggerContainer = styled.div`
  button {
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    border-radius: 42px;
    width: 100%;
  }

  [data-state='open'] > div {
    border-color: ${props => props.theme.colors.grey2};
  }
`;
