import React, { useRef, useState } from 'react';
import { SuggestionType, SearchMode } from 'src/types/search';
import { SuggestionWrapper, Suggestions } from './styles';

import { SuggesterReadOnly } from './modes/ReadOnly';
import { SuggesterBorderLess } from './modes/BorderLess';
import { SuggesterFull } from './modes/Full';
import Suggestion from './Suggestion';
import { useClickAway } from 'react-use';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface ISuggesterProps {
  readonly mode: SearchMode;
  readonly className?: string;
  readonly suggestion?: SuggestionType | null;
  readonly suggestions: SuggestionType[];
  readonly onSelect: (value: SuggestionType | null) => void;
  readonly onChangeInput: (search: string) => void;
}

const suggesterConfig = {
  [SearchMode.Readonly]: SuggesterReadOnly,
  [SearchMode.Borderless]: SuggesterBorderLess,
  [SearchMode.Full]: SuggesterFull,
};

function Suggester({ mode, suggestions, onSelect, className = '', ...props }: ISuggesterProps) {
  const suggesterRef = useRef<HTMLDivElement | null>(null);
  const [hasFocus, setHasFocus] = useState(false);

  useClickAway(suggesterRef, () => {
    setHasFocus(false);
    if(suggesterRef.current){
      enableBodyScroll(suggesterRef.current);
    }
  });

  const SuggesterVariant = suggesterConfig[mode];

  const handleFocus = () => {
    setHasFocus(true);
    if(suggesterRef.current){
      disableBodyScroll(suggesterRef.current);
    }
  };

  const createSelectHandler = (suggestion: SuggestionType) => () => {
    onSelect(suggestion);
    setHasFocus(false);
    if(suggesterRef.current){
      enableBodyScroll(suggesterRef.current);
    }
  };

  return (
    <SuggestionWrapper
      mode={mode}
      hasFocus={hasFocus}
      onFocus={handleFocus}
      ref={suggesterRef}
      className={`${className} ${hasFocus ? 'has-focus' : ''}`}
      data-testid="suggester-wrapper"
    >
      <SuggesterVariant
        {...props}
        onSelect={onSelect}
        hasFocus={hasFocus}
        onClose={() => setHasFocus(false)}
      />

      {mode !== SearchMode.Readonly && suggestions.length > 0 && hasFocus && (
        <Suggestions className={mode} hasFocus={hasFocus}>
          {suggestions.map(suggestion => (
            <Suggestion
              key={suggestion.ID}
              item={suggestion}
              onClick={createSelectHandler(suggestion)}
            />
          ))}
        </Suggestions>
      )}
    </SuggestionWrapper>
  );
}

export default Suggester;
