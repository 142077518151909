import styled, { css } from 'styled-components';

import { updatedMedia } from 'client/styles';
import Date from './BookingDate';

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DateWrapper = styled.div`
  cursor: pointer;
  position: relative;
  color: ${({ theme }) => theme.colors.grey3};
  font-weight: 500;
  font-size: 14px;
`;

export const CheckinDate = styled(Date)``;

export const CheckoutDate = styled(Date)``;

export const ButtonClose = styled.div`
  align-self: flex-end;
`;

export const Spacer = styled.div`
  display: none;
`;

export const Ready = styled.div`
  background-color: ${({ theme }) => theme.colors.grey8};
  bottom: 0;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 0.75em;
  position: absolute;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.families.primary};
  ${updatedMedia.desktop} {
    display: none;
  }
`;

export const DatePickerBase = styled.div<{ operationMode: string, openCalendar: boolean }>`
  &.readonly {
    ${DateWrapper} {
      white-space: nowrap;
      div {
        display: none;
      }
      svg {
        display: none;
      }
    }
    ${CheckinDate} {
      display: flex;
      &:after {
        display: block;
        content: '-';
        width: 10px;
        text-align: center;
      }
    }
    ${CheckoutDate} {
      display: flex;
      &:after {
        display: block;
        content: '·';
        width: 10px;
        text-align: center;
      }
    }
  }

  &.borderless {
    position: relative;
    ${ButtonClose} {
      display: none;
    }
    ${DateWrapper} {
      padding: 0 12px;
      color: ${({ theme }) => theme.colors.grey2};
      font-weight: 700;
      font-size: 16px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      div {
        display: none;
      }
      svg {
        margin-right: 6px;
        display: block;
        fill: ${({ theme }) => theme.colors.grey3};
      }
    }
    ${CheckinDate}, ${CheckoutDate} {
      padding: 8px;
      border-radius: 50px;
      border: 2px solid ${({ theme }) => theme.colors.grey8};
      &:hover {
        border-color: ${({ theme }) => theme.colors.grey2};
      }
    }
    ${CheckinDate} {
      ${({ operationMode }) => operationMode === 'checkin' && css`
        border-color: ${({ theme }) => theme.colors.grey2};
      `}
    }
    ${CheckoutDate} {
      ${({ operationMode }) => (operationMode === 'checkout' || operationMode === 'selecting') && css`
        border-color: ${({ theme }) => theme.colors.grey2};
      `}
    }
    ${Spacer} {
      display: block;
      height: 26px;
      width: 2px;
      background-color: ${({ theme }) => theme.colors.grey5};
      margin: 0 4px;
    }
  }

  &.full {
    border-radius: 8px;
    border: 2px solid ${props => props.theme.colors.grey5};
    ${({ openCalendar }) => !openCalendar && 'margin: 16px 0;'}
    ${DateWrapper} {
      color: ${({ theme }) => theme.colors.grey2};
      svg {
        display: none;
      }
      div {
        margin-bottom: 6px;
      }
      span {
        display: block;
        font-weight: 700;
        font-size: 16px;
      }
    }
    ${CheckinDate} {
      border-right: 2px solid ${props => props.theme.colors.grey5};
    }
    ${CheckinDate}, ${CheckoutDate} {
      padding: 8px 12px;
      width: 50%;
    }
    ${({ openCalendar }) => openCalendar && css`
      background-color: ${({ theme }) => theme.colors.grey8};
      padding: 0px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: none;
      z-index: 1;

      ${DatesContainer} {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
      }

      ${CheckinDate}, ${CheckoutDate} {
        display: none;
      }
    `}
  }
`;
