import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import { SuggestionType } from 'src/types/search';

import { SuggesterFullWrapper } from './styles';
import Input from 'src/comps/Atom/Inputs/Input/Input';
import ButtonReset from 'src/comps/ButtonReset';
import IconLocation from 'client/icons/Location';
import IconArrowBack from 'client/icons/ArrowBack';
import { ButtonIcon } from 'src/comps/Button/styles';
import { isProperty, isGeo } from 'src/context/search/utils';

interface SuggesterFullProps {
  suggestion?: SuggestionType | null;
  onChangeInput: (value: string) => void;
  onSelect: (value: SuggestionType | null) => void;
  hasFocus?: boolean;
  onClose: () => void;
}

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <ButtonIcon onClick={onClick}>
    <IconArrowBack size={20} />
  </ButtonIcon>
)

export function SuggesterFull({
  suggestion,
  onChangeInput,
  onSelect,
  hasFocus = false,
  onClose
}: SuggesterFullProps) {
  const intl = useIntl();
  const placeholderText = intl.formatMessage({ id: 'search.placeholder' });
  const mapAreaText = intl.formatMessage({ id: 'global.search-map-area' });

  const isGeoSuggestion = isGeo(suggestion?.Type ?? '');
  const suggestionText = isProperty(suggestion?.Type) ? suggestion?.Text : suggestion?.Description;
  const initialSearchTerm = isGeoSuggestion ? mapAreaText : suggestionText ?? '';

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const externalOnChangeInput = useCallback(
    debounce(
      data => {
        onChangeInput(data);
      },
      300,
      {
        leading: false,
        trailing: true,
      }
    ),
    [onChangeInput]
  );

  function handleChangeInput(text: string) {
    setSearchTerm(text);
    externalOnChangeInput(text);
  }

  function handleResetInput() {
    setSearchTerm('');
    onSelect(null);
  }

  return (
    <SuggesterFullWrapper hasFocus={hasFocus} data-testid='suggester-full'>
      {hasFocus && <CloseButton onClick={onClose} />}
      <Input
        label=''
        icon={!hasFocus && <IconLocation size={20} />}
        onChange={handleChangeInput}
        value={searchTerm}
        placeholder={placeholderText}
      />
      <ButtonReset
        handleClick={handleResetInput}
        visible={searchTerm?.length > 0}
      />
    </SuggesterFullWrapper>
  );
}
