import React from 'react';
import { useIntl } from 'react-intl';

import People from 'client/icons/People';
import PaxesText from 'src/comps/Atom/PaxesText';
import { SearchMode } from "src/types/search";

import { PaxesFullLabel, PaxesSelectorInput, PillFullMode } from './styles';

function PaxesPill({ paxes, mode = SearchMode.Full }: {
  paxes: {
    rooms: number;
    adults: number;
    children: number;
  },
  mode?: SearchMode;
}) {
  const intl = useIntl();

  if (mode === SearchMode.Borderless) {
    return <PillFullMode leftIcon={<People />} label={<PaxesText paxes={paxes} mode={mode} />} />;
  }

  if (mode === SearchMode.Full) {
    return (
      <PaxesSelectorInput>
        <PaxesFullLabel>
          {intl.formatMessage({ id: 'global.guests' })}
        </PaxesFullLabel>
        <PaxesText paxes={paxes} mode={mode} />
      </PaxesSelectorInput >
    )
  }
  return null;
}

export default PaxesPill;
