import styled, { css } from "styled-components";

import { SearchMode } from 'src/types/search';

export const SuggestionWrapper = styled.div<{ mode: SearchMode, hasFocus: boolean }>`
  position: relative;
  ${({ mode }) => mode === SearchMode.Readonly && css`
    width: 100%;
    display: table;
    table-layout: fixed;
  `}
  ${({ hasFocus, mode }) => mode === SearchMode.Full && hasFocus && css`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.colors.grey8};
    z-index: 1;
    overflow-y: scroll;
  `}
  ${({ mode }) => mode === SearchMode.Borderless && css`
    width: 40%;
  `}
`;

export const SuggestionText = styled.div`
  color: ${props => props.theme.colors.grey2};
  font-family: ${props => props.theme.fonts.families.primary};
  font-weight: 700;
`;

export const Suggestions = styled.ul<{ hasFocus: boolean }>`
  &.borderless {
    border: 2px solid ${props => props.theme.colors.grey2};
    border-radius: 8px;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    z-index: 11;
    background: ${props => props.theme.colors.grey8};
  }

  &.full {
    border: none;
    li {
      border-radius: 0;
    }
  }
`;
