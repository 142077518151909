import styled from 'styled-components';

export const PillWrapper = styled.div<{ selected: boolean }>`
  font-size: ${props => props.theme.fonts.sizes.s};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  gap: 8px;
  color: ${props => (props.selected ? props.theme.colors.grey8 : props.theme.colors.grey3)};
  border: 2px solid
    ${props => (props.selected ? props.theme.colors.grey2 : props.theme.colors.grey5)};
  background: ${props => (props.selected ? props.theme.colors.grey2 : props.theme.colors.grey8)};
  border-radius: 50px;
  white-space: nowrap;
  cursor: pointer;

  svg {
    fill: ${props => (props.selected ? props.theme.colors.grey8 : props.theme.colors.grey3)};
  }

  &:hover {
    border-color: ${props =>
      props.selected ? props.theme.colors.grey3 : props.theme.colors.grey2};
    background: ${props => (props.selected ? props.theme.colors.grey3 : props.theme.colors.grey8)};
  }
`;
