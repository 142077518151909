import { useContext } from "react"
import { SearchContext } from "src/context/search"

export const useSearch = () => {
    const search = useContext(SearchContext);

    if (!search) {
        throw new Error("Missing SearchContext provider");
    }

    return search;
}