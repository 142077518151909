import styled from 'styled-components';
import Pill from 'src/comps/Atom/Pill';

export const PaxesSelectorInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 6px 12px;
  border: 2px solid ${props => props.theme.colors.grey5};
  border-radius: 8px;
  background: ${props => props.theme.colors.grey8};
`;

export const PaxesFullLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.grey3};
  font-family: ${({ theme }) => theme.fonts.families.primary};
`;

export const PillFullMode = styled(Pill)`
  border-color: ${props => props.theme.colors.grey8};
  margin-right: 4px;
  &:hover {
    border: 2px solid ${props => props.theme.colors.grey2};
  }
`;
