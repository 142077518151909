import React, { useState } from 'react';
import { noop } from 'lodash';

import Dropdown from 'src/comps/Atom/Drowpdown';
import PaxesPill from './PaxesPill';
import { PaxesWrapper } from './styles';
import PaxesText from 'src/comps/Atom/PaxesText';
import PaxesSelector from './PaxesSelector';
import { getPaxes } from './helpers';
import { Room, SearchMode } from 'src/types/search';

interface IPaxesProps {
  initialValue: Room[];
  onChange: (value: Room[]) => void;
  mode?: SearchMode;
}

function Paxes({ initialValue, onChange = noop, mode = SearchMode.Full }: IPaxesProps) {
  const [isOpen, setIsOpen] = useState(false);

  const paxesPlain = getPaxes(initialValue);

  if (mode === SearchMode.Readonly) {
    return <PaxesText paxes={paxesPlain} mode={mode} />;
  }

  return (
    <PaxesWrapper mode={mode} className="paxes-wrapper">
      <Dropdown
        onShowChange={setIsOpen}
        show={isOpen}
        showClose={false}
        trigger={<PaxesPill mode={mode} paxes={paxesPlain} />}
      >
        <PaxesSelector
          paxes={initialValue}
          onChange={onChange}
          onClose={() => setIsOpen(false)}
          mode={mode}
        />
      </Dropdown>
    </PaxesWrapper>
  );
}

export default Paxes;
