import styled from 'styled-components';

import { updatedMedia } from 'client/styles';

export const PaxesBase = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: lowercase;
  font-family: ${props => props.theme.fonts.families.primary};
  color: ${props => props.theme.colors.grey2};

  &.full, &.borderless {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.readonly {
    font-size: 14px;
    font-weight: 500;
    ${updatedMedia.onlyMobile} {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.borderless {
    display: table-cell;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
