import { updatedMedia } from "client/styles";
import styled from "styled-components";

export const RoomWrapper = styled.div<{ isFirstRoom: boolean }>`
  padding: 20px;

  border-top: 1px solid ${props => props.theme.colors.grey5};
  ${props => props.isFirstRoom && `border-top: none;`}

  ${updatedMedia.onlyMobile} {
    width: 100%;
  }
  ${updatedMedia.desktop} {
    width: 400px;
  }
`;

export const RoomHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RoomTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey2};
`;

export const RemoveRoom = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.colors.brand1};
`;

export const ItemSelectorTitle = styled.span`
  text-transform: capitalize;
`;

export const ItemSelector = styled.li`
  margin-top: 20px;
`;

export const AgeRange = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.4;
  color: ${props => props.theme.colors.grey2};
`;

export const TitleAges = styled.span`
  display: block;
  margin: 1rem 0;
`;

export const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
