import React from 'react';

import { SuggestionType } from 'src/types/search';

import { SuggesterReadOnlyWrapper } from './styles';
import { useIntl } from 'react-intl';
import { isProperty, isGeo } from 'src/context/search/utils';

interface ISuggesterReadOnlyProps {
  suggestion?: SuggestionType | null;
}

export function SuggesterReadOnly({ suggestion }: ISuggesterReadOnlyProps) {
  const intl = useIntl();
  if (!suggestion) return null;

  const isGeoSuggestion = isGeo(suggestion?.Type ?? '');
  const mapAreaText = intl.formatMessage({ id: 'global.search-map-area' });
  const suggestionText = isProperty(suggestion.Type) ? suggestion.Text : suggestion.Description;

  return (
    <SuggesterReadOnlyWrapper data-testid='suggester-readonly'>{isGeoSuggestion ? mapAreaText : suggestionText}</SuggesterReadOnlyWrapper>
  );
}
